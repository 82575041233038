import React from 'react'
import { MdOutlinePhone } from 'react-icons/md'
import { ReactTyped } from 'react-typed'

const Hero = () => {
  return (
    <div className="bg-[url('hero.png')] bg-cover bg-top h-screen -translate-y-[108px] -z-20">
      <div className='flex flex-col justify-center items-center text-white h-full text-center'>
        <h1 className='text-7xl md:text-8xl font-bold p-4'>Your Hair, Our Passion</h1>
        <p className='text-xl p-4'>Expert hairdresser with 30+ years of experience</p>
        <a href='tel: +442036022422' className='p-4'><button className='bg-fuchsia-500 px-5 py-3 ease-in-out duration-150 rounded-2xl flex items-center justify-center min-w-40 pr-7 text-xl hover:bg-fuchsia-900 hover:scale-105 hover:ease-in-out hover:duration-150 hover:-rotate-2'><MdOutlinePhone style={{fontSize: '22px', marginRight: '4px'}}/>Book an appointment</button></a>
      </div>
    </div>
  )
}

export default Hero