import React from 'react'
import Map from './Map'

const Directions = () => {

  return (
    <div className='flex justify-evenly flex-col lg:flex-row text-white max-w-[3000px]' id='directions'>
        <div className='px-12 flex flex-col justify-center'>
            <h1 className='py-4'>How to get to us</h1>
            <p className='py-4'>Our salon is located on Palmerston Road in Walthamstow, just a 10 min walk away from the Walthamstow market</p>
            <p>81 Palmerston Road</p>
            <p>London</p>
            <p>E17 6PU</p>
            <br></br>
            <br></br>
            <i>Please be aware we accept appointments only and do not accept walk-ins. Thank you.</i>
        </div>
        <div className='px-0 md:px-12 py-12'>
            <div className='h-full min-h-[50vh] w-[400px] md:w-[800px] max-w-[100vw] relative'>
              <div className='absolute left-0 right-0 top-0 bottom-0'>
              <Map/>
              </div>
            </div>
        </div>
    </div>
  )
}

export default Directions