import Hero from './components/Hero.jsx';
import Nav from './components/Nav.jsx'
import IndexAbout from './components/IndexAbout.jsx';
import { IconContext } from 'react-icons';
import Services from './components/Services.jsx';
import PriceList from './components/PriceList.jsx';
import Directions from './components/Directions.jsx';
import Footer from './components/Footer.jsx';

function App() {
  return (
    <div>
      <IconContext.Provider value={{style: {fontSize: '22px', marginRight: '4px'}}}>
      <Nav/>
      <Hero/>
      <IndexAbout/>
      <Services/>
      <PriceList/>
      <Directions/>
      <Footer/>
      </IconContext.Provider>
    </div>
  );
}

export default App;
