import React from 'react'
import aboutimg from '../aboutimg.jpg'
import Button from './Button'
import { MdOutlinePhone } from 'react-icons/md'

const indexAbout = () => {
  return (
    <div id='about' className='md:py-16 w-full max-w-[3000px] mx-auto flex flex-col md:flex-row justify-evenly text-white'>
        <div className='flex items-center justify-center px-12'>
            <img src={aboutimg} className='w-[550px] rounded-3xl outline outline-2 outline-fuchsia-500 outline-offset-[16px] outline-' alt='about'/>
        </div>
        <div className='flex flex-col items-start justify-center px-12 py-6 max-w-[1200px]'>
            <h1 className='text-6xl font-bold py-4'>About Sonish</h1>
            <p className='text-xl py-4'>Sonish is a talented and highly experience hair dresser & stylist. She began her journey in the industry at the age of 16 working with her sister before moving and setting up shop in London.</p>
            <p className='text-xl py-4'>With over 30 years of experience, Sonish has honed her craft to perfection, mastering a wide range of techniques and styles to suit your needs. See what we offer with the button below.</p>
            <Button href={'tel: +442036022422'} icon={<MdOutlinePhone/>} text={'Call for an appointment'}/>
        </div>
    </div>
  )
}

export default indexAbout