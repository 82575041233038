import React from 'react'


const Services = () => {
  return (
    <div className='flex text-white w-full min-h-[80vh] lg:min-h-[60vh] py-16 lg:py-16 max-w-[3000px]'>
      <div className='flex w-full justify-evenly items-center flex-wrap'>
        <div className='flex flex-col w-full md:w-[30vw] my-3 text-center group relative justify-center'>
          <div className='min-w-[300px] min-h-[500px] lg:min-h-[60vh] bg-[url(hair-dye.jpg)] bg-center bg-cover opacity-60 ease-in-out duration-300 group-hover:opacity-25 group-hover:ease-in-out group-hover:duration-200'></div>
          <h2 className='text-4xl absolute top-[70%] lg:top-[85%] w-full ease-in-out duration-300 group-hover:top-16 group-hover:bottom-0 group-hover:ease-in-out group-hover:duration-300'>Colouring</h2>
          <p className='px-12 ease-in-out absolute duration-300 top-full opacity-0 w-full group-hover:opacity-100 group-hover:block group-hover:top-40 group-hover:ease-in-out group-hover:duration-700'>Transform your look with our expert colouring services. From vibrant hues to natural tones, we will help you achieve the perfect shade that complements your style and personality.</p>
        </div>
        <div className='flex flex-col w-full md:w-[30vw] my-3 text-center group relative justify-center'>
          <div className='min-w-[300px] min-h-[500px] lg:min-h-[60vh] bg-[url(haircut.jpg)] bg-center bg-cover opacity-60 ease-in-out duration-300 group-hover:opacity-25 group-hover:ease-in-out group-hover:duration-200'></div>
          <h2 className='text-4xl absolute top-[70%] lg:top-[85%] w-full ease-in-out duration-300 group-hover:top-16 group-hover:bottom-0 group-hover:ease-in-out group-hover:duration-300'>Cutting</h2>
          <p className='px-12 ease-in-out absolute duration-300 top-full opacity-0 w-full group-hover:opacity-100 group-hover:block group-hover:top-40 group-hover:ease-in-out group-hover:duration-700'>Experience precision and creativity with our cutting services. We are trained in the latest techniques to deliver a haircut that suits your face shape and enhances your unique features.</p>
        </div>
        <div className='flex flex-col w-full md:w-[30vw] my-3 text-center group relative justify-center'>
          <div className='min-w-[300px] min-h-[500px] lg:min-h-[60vh] bg-[url(hair-style.jpg)] bg-center bg-cover opacity-60 ease-in-out duration-300 group-hover:opacity-25 group-hover:ease-in-out group-hover:duration-200'></div>
          <h2 className='text-4xl absolute top-[70%] lg:top-[85%] w-full ease-in-out duration-300 group-hover:top-16 group-hover:bottom-0 group-hover:ease-in-out group-hover:duration-300'>Styling</h2>
          <p className='px-12 ease-in-out absolute duration-300 top-full opacity-0 w-full group-hover:opacity-100 group-hover:block group-hover:top-40 group-hover:ease-in-out group-hover:duration-700'>Elevate your everyday look or get ready for a special occasion with our professional styling services. Whether you desire sleek and straight, voluminous curls, or an intricate updo, we will make your hair vision a reality.</p>
        </div>
      </div>
    </div>
  )
}

export default Services