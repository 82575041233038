import React from 'react'

const Footer = () => {
  return (
    <div className='flex flex-col text-white text-center py-16'>
        <div>
            <h4 className='text-fuchsia-500 text-4xl font-bold py-2'>Sonish</h4>
        </div>
        <div>
            <ul className='flex justify-center'>
                <a href='#priceList'><li className='py-1 px-6'>Price List</li></a>
                <a href='#about'><li className='py-1 px-6'>About</li></a>
                <a href='#directions'><li className='py-1 px-6'>Visit Us</li></a>
            </ul>
        </div>
        <p className='text-sm py-4 text-gray-500'>Copyright 2024 Sonish Ladies Hair Salon</p>
        
    </div>
  )
}

export default Footer