import React from 'react'
import { RxScissors } from "react-icons/rx";
import Button from './Button';
import { MdOutlinePhone } from 'react-icons/md';

const PriceList = () => {
  return (
    <div id='priceList' className='py-16 text-white text-center flex flex-col items-center'>
        <RxScissors style={{margin: 'auto', fontSize: '48px'}}/>
        <h1 className='text-center'>Price List</h1>
        <p className='py-4'>Our full list of services & prices. Please give us a call if you have any questions.</p>
        <i>* indicates price starting from</i>
        <div className='border-2 border-fuchsia-500 text-xl py-12 rounded-xl w-full lg:w-[800px] mx-auto mt-8 mb-6 flex justify-evenly'>
            <ul className='text-start'>
                <li>Wash & cut</li>
                <li>Wash, cut, and finishing</li>
                <li>Wash & blow dry</li>
                <br></br>
                <li>Tint full head</li>
                <li>Tint roots</li>
                <li>Highlight full head</li>
                <li>Highlight half head</li>
                <br></br>
                <li>Perm</li>
                <li>Ombre</li>
                <li>Sets</li>
                <li>Hair treatment</li>
                <li>Party Style</li>
                <br></br>
                <li>Makeup</li>
                <li>Eyebrow tint</li>
                <li>Eyelash tint</li>
                <li>Eyebrow shape</li>
            </ul>
            <ul className='text-start'>
                <li>£25*</li>
                <li>£35*</li>
                <li>£25*</li>
                <br></br>
                <li>£60*</li>
                <li>£40*</li>
                <li>£80*</li>
                <li>£60*</li>
                <br></br>
                <li>£70*</li>
                <li>£80*</li>
                <li>£20*</li>
                <li>£30*</li>
                <li>£45*</li>
                <br></br>
                <li>£20*</li>
                <li>£8</li>
                <li>£8</li>
                <li>£7</li>
            </ul>
        </div>
        <Button href={'tel: +442036022422'} text={'Get an appointment now'} icon={<MdOutlinePhone/>}/>
    </div>
  )
}

export default PriceList