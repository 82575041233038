import React, { useState, useEffect } from 'react'
import { MdOutlinePhone } from "react-icons/md";
import { LuMenu } from "react-icons/lu";
import { IoCloseOutline } from "react-icons/io5";

const Nav = () => {

  const [mobileNav, setCollapse] = useState(false)
  const [isScrolled, setIsScrolled] = useState(false)

  const handleNav = () => {
    setCollapse(!mobileNav);
  }

  const handleScroll = () => {
    if (window.scrollY > 150) {
      setIsScrolled(true);
    } else {
      setIsScrolled(false);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <div className={!isScrolled ? 'text-white flex justify-between items-center px-3 lg:px-12 p-6 mx-auto sticky top-0 w-full bg-transparent z-10 ease-in-out duration-500' : 'text-white flex justify-between items-center px-3 lg:px-12 p-6 mx-auto sticky top-0 w-full bg-[#09000C] z-10 ease-in-out duration-500'}>
        <h1 className='font-bold text-6xl text-fuchsia-500'>Sonish</h1>
        <ul className='hidden items-center lg:flex'>
            <li className='px-4 text-xl'><a href='#priceList'>Price List</a></li>
            <li className='px-4 text-xl'><a href='#about'>About</a></li>
            <li className='px-4 text-xl'><a href='#directions'>Visit Us</a></li>
            <li className='px-4 text-xl'><a href='tel: +442036022422'><button className='bg-fuchsia-500 px-5 py-2 ease-in-out duration-150 rounded-2xl flex items-center justify-center min-w-40 pr-7 text-xl hover:bg-fuchsia-900 hover:scale-105 hover:ease-in-out hover:duration-150 hover:-rotate-2'><MdOutlinePhone style={{fontSize: '22px', marginRight: '4px'}}/>Call Us</button></a></li>
        </ul>
        <div className='lg:hidden' onClick={handleNav}>
          {mobileNav ? <IoCloseOutline size={45}/> : <LuMenu size={45}/>}
        </div>
        <div className={mobileNav ? 'fixed px-6 left-0 top-0 pt-9 w-[70%] h-screen border-r-[#16001F] border-r-2 bg-black ease-in-out duration-200' : 'fixed px-6 left-[-100%] top-0 pt-9 w-[80%] h-screen bg-black ease-in-out duration-200'}>
        <h1 className='font-bold text-5xl text-fuchsia-500 px-4 py-5'>Sonish</h1>
        <ul>
            <li className='px-4 py-5 text-2xl'><a href='#about'>About</a></li>
            <li className='px-4 py-5 text-2xl'><a href='#priceList'>Price List</a></li>
            <li className='px-4 py-5 text-2xl'><a href='#directions'>Visit Us</a></li>
            <li className='px-4 py-5 text-2xl'><a href='tel: +442036022422'><button className='bg-fuchsia-500 px-5 py-2 ease-in-out duration-150 rounded-2xl flex items-center justify-center min-w-40 pr-7 text-xl hover:bg-fuchsia-900 hover:scale-105 hover:ease-in-out hover:duration-150 hover:-rotate-2'><MdOutlinePhone style={{fontSize: '22px', marginRight: '4px'}}/>Call Us</button></a></li>
        </ul>
        </div>
    </div>
  )
}

export default Nav